import React from "react";

interface DialogContentProps {
  children: React.ReactNode;
}

const DialogContent = ({ children }: DialogContentProps): JSX.Element => {
  return <>{children}</>;
};

export default DialogContent;
