import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

const PatientResearchStudy = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <FormInput
      id="patientResearchStudy"
      label="Research study name"
      help="Optional (e.g. DEL)"
      error={formState.errors.patientResearchStudy}
      {...register("patientResearchStudy")}
    />
  );
};

export default PatientResearchStudy;
