import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import caseListReducer from "./caseListSlice";
import metadataReducer from "./metadataSlice";
import specialsListReducer from "./specialsListSlice";
import userListReducer from "./userListSlice";

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  auth: authReducer,
  metadata: metadataReducer,
  caseList: caseListReducer,
  specialsList: specialsListReducer,
  userList: userListReducer,
});

// Can be used for https://redux.js.org/usage/writing-tests
export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
