import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";

import { FormMode } from "../../forms/FormWrapper";
import { CaseUiData } from "../../forms/schema/CaseUiSchema";
import { CaseDialogType } from "../../routes/CaseEdit";
import { dataService } from "../../services/data.service";
import LimsCaseLink from "../common/LimsCaseLink";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogError,
  DialogSubtitle,
  DialogTitle,
} from "../dialogs";

export const TEST_ID_DIALOG_SAVE_CASE_BUTTON = "DialogSaveCaseButton";
export const TEST_ID_DIALOG_ADD_CASE_BUTTON = "DialogAddCaseButton";
export const TEST_ID_DIALOG_CANCEL_BUTTON = "DialogCancelButton";
export const TEST_ID_DIALOG_CLOSE_BUTTON = "DialogCloseButton";
export const TEST_ID_DIALOG_CASE_LIST_LINK = "DialogCaseListLink";
export const TEST_ID_DIALOG_LIMS_LINK = "DialogLimsLink";
export const TEST_ID_DIALOG_LOCK_CASE_BUTTON = "DialogLockCaseButton";
export const TEST_ID_DIALOG_ERROR_MESSAGE = "DialogErrorMessage";
export const TEST_ID_DIALOG_GUIDANCE_TEXT = "DialogGuidanceText";

interface CaseSaveDialogProps {
  formMode: FormMode;
  formData: CaseUiData;
  labNumber: string;
  canLockCase?: boolean;
  caseVersionId?: string;
  updatePage: () => void;
  updateCaseVersionId?: (x: string) => void;
  openCaseDialog?: (d: CaseDialogType, e?: MouseEvent) => void;
  closeCaseDialog: (e?: MouseEvent) => void;
}

const CaseSaveDialog = ({
  formMode,
  formData,
  labNumber,
  canLockCase,
  caseVersionId,
  updatePage,
  updateCaseVersionId,
  openCaseDialog,
  closeCaseDialog,
}: CaseSaveDialogProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [limsCaseId, setLimsCaseId] = useState<string>("");

  const saveCase = async (): Promise<void> => {
    setError("");
    setBusy(true);
    const response =
      formMode === "create"
        ? await dataService.createCase(formData)
        : await dataService.updateCase(labNumber, formData, caseVersionId!);
    if (response.data) {
      const { limsCaseId, caseVersionId } = response.data;
      setError("");
      setLimsCaseId(limsCaseId);
      // Update case edit page URL because lab number may have changed
      if (formMode === "update") updatePage();
      // Update versionId to trigger case reload in parent component
      if (updateCaseVersionId) updateCaseVersionId(caseVersionId);
    } else {
      setError(response.error?.msg ?? "Unexpected error. Please try again later.");
    }
    setBusy(false);
  };

  // We know that the case was saved if the API returned a LIMS Case ID
  const isCaseSaved: boolean = !!limsCaseId;

  const handleClose = (e?: MouseEvent) => {
    if (isCaseSaved && formMode === "create") {
      // Resets the form in NewCase.tsx ready to book in another case
      updatePage();
    }
    if (!busy) {
      closeCaseDialog(e);
    }
  };

  const actions: { id: string; visible: boolean; button: JSX.Element }[] = [
    {
      id: "buttonSave",
      visible: !isCaseSaved,
      button: (
        <button
          className="button is-primary"
          disabled={busy}
          data-testid={TEST_ID_DIALOG_SAVE_CASE_BUTTON}
          onClick={saveCase}
        >
          <FontAwesomeIcon
            spin={busy}
            icon={busy ? faRotate : faCheckCircle}
            className="mr-2"
          />
          {busy ? "Saving..." : "Confirm"}
        </button>
      ),
    },
    {
      id: "buttonCancel",
      visible: !isCaseSaved,
      button: (
        <button
          className="button is-light"
          disabled={busy}
          data-testid={TEST_ID_DIALOG_CANCEL_BUTTON}
          onClick={handleClose}
        >
          Cancel
        </button>
      ),
    },
    {
      id: "buttonAddCase",
      visible: isCaseSaved && formMode === "create",
      button: (
        <button
          className="button is-primary"
          data-testid={TEST_ID_DIALOG_ADD_CASE_BUTTON}
          onClick={handleClose}
        >
          Add another case
        </button>
      ),
    },
    {
      id: "linkLimsCase",
      visible: isCaseSaved && formMode === "create",
      button: (
        <LimsCaseLink
          limsCaseId={limsCaseId}
          className="button is-light"
          dataTestId={TEST_ID_DIALOG_LIMS_LINK}
        />
      ),
    },
    {
      id: "linkCaseList",
      visible: isCaseSaved && formMode === "update" && !canLockCase,
      button: (
        <Link
          to="/cases"
          className="button is-primary"
          data-testid={TEST_ID_DIALOG_CASE_LIST_LINK}
        >
          Return to case list
        </Link>
      ),
    },
    {
      id: "buttonLockCase",
      visible: isCaseSaved && !!canLockCase,
      button: (
        <button
          className="button is-primary"
          data-testid={TEST_ID_DIALOG_LOCK_CASE_BUTTON}
          onClick={() => openCaseDialog?.(CaseDialogType.STATE)}
        >
          Lock case
        </button>
      ),
    },
    {
      id: "buttonClose",
      visible: isCaseSaved && formMode === "update",
      button: (
        <button
          className="button is-light"
          data-testid={TEST_ID_DIALOG_CLOSE_BUTTON}
          onClick={handleClose}
        >
          Close
        </button>
      ),
    },
  ];

  return (
    <Dialog>
      <DialogTitle>{formMode === "create" ? "Submit case" : "Save changes"}</DialogTitle>
      <DialogSubtitle>
        {formMode === "create" ? "New" : "Update"} case {labNumber}
      </DialogSubtitle>
      <DialogContent>
        <p data-testid={TEST_ID_DIALOG_GUIDANCE_TEXT}>
          {!isCaseSaved
            ? "It may take a while to sync with the LIMS."
            : `Success! The case was also ${formMode}d in the LIMS.`}
        </p>
        <DialogActions>
          {actions
            .filter((action) => action.visible)
            .map((action) => (
              <React.Fragment key={action.id}>{action.button}</React.Fragment>
            ))}
        </DialogActions>
        <DialogError testId={TEST_ID_DIALOG_ERROR_MESSAGE}>{error}</DialogError>
      </DialogContent>
    </Dialog>
  );
};

export default CaseSaveDialog;
