import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faLock, faRotate, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, MouseEvent, useEffect, useState } from "react";

import { CaseInfo, CaseStateReasonForChange } from "../../schemas/ApiSchema";
import { dataService } from "../../services/data.service";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogError,
  DialogSubtitle,
  DialogTitle,
} from "../dialogs";
import RadioOptionWithDescription from "../forms/RadioOptionWithDescription";

export const TEST_ID_CASE_STATE_DIALOG_TITLE = "CaseStateDialogTitle";
export const TEST_ID_CASE_STATE_DIALOG_SUBTITLE = "CaseStateDialogSubtitle";
export const TEST_ID_CASE_STATE_DIALOG_SUBMIT_BUTTON = "CaseStateDialogSubmitButton";
export const TEST_ID_CASE_STATE_DIALOG_SUBMIT_BUTTON_ICON =
  "CaseStateDialogSubmitButtonIcon";

interface CaseStateDialogProps {
  labNumber: string;
  caseInfo: CaseInfo;
  updateCaseVersionId: (x: string) => void;
  closeCaseDialog: (e?: MouseEvent) => void;
}

interface ReasonOption {
  reason: CaseStateReasonForChange;
  label: string;
  description: string;
  confirmation?: string;
}

const reasonsMap: {
  [key in CaseStateReasonForChange]: {
    label: string;
    description: string;
    confirmation?: string;
  };
} = {
  [CaseStateReasonForChange.LABKIT_MATERIAL_CORRECTION]: {
    label: "Correction",
    description:
      "Minor, non-clinical errors, e.g. misspelled names or patient identifiers.",
  },
  [CaseStateReasonForChange.LABKIT_NON_MATERIAL_CORRECTION]: {
    label: "Lab",
    description:
      "Changes relating to scanned images, specials or other technical lab work.",
  },
  [CaseStateReasonForChange.MICRO_CORRECTION]: {
    label: "Clinical",
    description:
      "You have been authorised by the Quality Lead to start the micro report amendment process.",
    confirmation:
      "I will tell the reporting pathologist that PathKit is ready for their micro report amendment",
  },
};

const CaseStateDialog = ({
  labNumber,
  caseInfo: {
    caseVersionId,
    permissions: {
      operations: [{ operation, reasons }],
    },
  },
  updateCaseVersionId,
  closeCaseDialog,
}: CaseStateDialogProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<CaseStateReasonForChange>();
  const [confirmationFlag, setConfirmationFlag] = useState<boolean>(false);

  // Reset the confirmation checkbox whenever the reason changes
  useEffect(() => setConfirmationFlag(false), [selectedReason]);

  const selectedReasonRequiresConfirmation: boolean =
    !!selectedReason && !!reasonsMap[selectedReason]?.confirmation;

  const isSubmitButtonDisabled: boolean =
    busy || !selectedReason || (selectedReasonRequiresConfirmation && !confirmationFlag);

  const handleClose = () => !busy && closeCaseDialog();

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (!selectedReason) return;
    setError("");
    setBusy(true);
    const response =
      operation === "Unlock"
        ? await dataService.unlockCase(labNumber, caseVersionId, selectedReason)
        : await dataService.lockCase(labNumber, caseVersionId, selectedReason);
    if (response.data) {
      updateCaseVersionId(response.data.newVersionId);
      handleClose();
    } else {
      setBusy(false);
      setError(response.error?.msg ?? "Unexpected error. Please try again later.");
    }
  };

  // Dialog headings
  const dialogTitle: string = `${operation} case`;
  const dialogSubtitle: string = `Reason for ${operation.toLowerCase()}ing ${labNumber}`;

  // Primary action
  const buttonLabel: string = `${operation} case`;
  const buttonLabelBusy: string = `${operation}ing...`;
  const buttonIcon: IconDefinition = operation === "Unlock" ? faUnlock : faLock;

  // Radio buttons determined by `reasons` from API
  const visibleReasons: ReasonOption[] = Object.entries(reasonsMap)
    .filter(([reason]) => {
      return reasons.includes(reason as CaseStateReasonForChange);
    })
    .map(([reason, { label, description }]) => {
      return {
        reason: reason as CaseStateReasonForChange,
        label,
        description,
      };
    });

  const ReasonOption = ({ reason, label, description }: ReasonOption): JSX.Element => {
    return (
      <RadioOptionWithDescription
        id={reason}
        name="submissionType"
        value={reason}
        label={label}
        description={description}
        selectedValue={selectedReason}
        disabled={busy}
        onChange={(e) => setSelectedReason(e.target.value as CaseStateReasonForChange)}
      />
    );
  };

  const ConfirmationCheckbox = (): JSX.Element | null => {
    const confirmationCheckboxLabel =
      selectedReason && reasonsMap[selectedReason]?.confirmation;

    if (!confirmationCheckboxLabel) return null;
    return (
      <div className="notification is-warning">
        <div className="columns is-mobile is-variable is-2">
          <div className="column is-narrow">
            <input
              type="checkbox"
              id="confirmationFlag"
              checked={confirmationFlag}
              disabled={busy}
              onChange={(e) => setConfirmationFlag(e.target.checked)}
            />
          </div>
          <label
            htmlFor="confirmationFlag"
            className="column is-clickable has-text-weight-bold"
          >
            {confirmationCheckboxLabel}
          </label>
        </div>
      </div>
    );
  };

  return (
    <Dialog onClose={handleClose}>
      <DialogTitle testId={TEST_ID_CASE_STATE_DIALOG_TITLE}>{dialogTitle}</DialogTitle>
      <DialogSubtitle testId={TEST_ID_CASE_STATE_DIALOG_SUBTITLE}>
        {dialogSubtitle}
      </DialogSubtitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {visibleReasons.map(({ reason, label, description }) => {
            return (
              <ReasonOption
                key={reason}
                reason={reason}
                label={label}
                description={description}
              />
            );
          })}
          <ConfirmationCheckbox />
          <DialogActions>
            <button
              type="submit"
              className="button is-primary"
              disabled={isSubmitButtonDisabled}
              data-testid={TEST_ID_CASE_STATE_DIALOG_SUBMIT_BUTTON}
            >
              <FontAwesomeIcon
                spin={busy}
                icon={busy ? faRotate : buttonIcon}
                className="mr-2"
                data-testid={TEST_ID_CASE_STATE_DIALOG_SUBMIT_BUTTON_ICON}
              />
              {busy ? buttonLabelBusy : buttonLabel}
            </button>
            <button className="button is-light" disabled={busy} onClick={handleClose}>
              Cancel
            </button>
          </DialogActions>
          <DialogError>{error}</DialogError>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CaseStateDialog;
