import React from "react";

interface DialogSubtitleProps {
  children: React.ReactNode;
  testId?: string;
}

const DialogSubtitle = ({
  children,
  testId,
}: DialogSubtitleProps): JSX.Element | null => {
  if (!children) return null;
  return (
    <h4 className="title is-5 mb-4" data-testid={testId}>
      {children}
    </h4>
  );
};

export default DialogSubtitle;
