import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Special } from "../schemas/SpecialRequestSchema";
import { CustomError, dataService } from "../services/data.service";

export interface GetSpecialsResponse {
  specials: Special[];
}

export interface SpecialsListState {
  status: "pending" | "succeeded" | "failed";
  error?: CustomError;
  results: Special[];
  total: number;
}

const initialState: SpecialsListState = {
  status: "pending",
  error: undefined,
  results: [],
  total: 0,
};

export const fetchSpecials = createAsyncThunk<
  GetSpecialsResponse,
  void,
  { rejectValue: CustomError }
>("specials/fetchSpecials", async (_, { rejectWithValue }) => {
  const response = await dataService.getSpecials();
  return response.data ?? rejectWithValue(response.error);
});

export const specialsListSlice = createSlice({
  name: "specialsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecials.pending, (state) => {
        state.error = undefined;
        state.status = "pending";
      })
      .addCase(
        fetchSpecials.fulfilled,
        (state, action: PayloadAction<GetSpecialsResponse>) => {
          const { specials } = action.payload;
          state.status = "succeeded";
          state.results = specials;
          state.total = specials.length;
        }
      )
      .addCase(fetchSpecials.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default specialsListSlice.reducer;
