import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import CaseSaveDialog from "../components/cases/CaseSaveDialog";
import CaseStateBanner from "../components/cases/CaseStateBanner";
import CaseStateDialog from "../components/cases/CaseStateDialog";
import CaseTimeline from "../components/cases/CaseTimeline";
import { FormSelectOption } from "../components/forms/FormSelect";
import PageHeader from "../components/pages/PageHeader";
import FormWrapper, { FormRef } from "../forms/FormWrapper";
import { CaseUiData } from "../forms/schema/CaseUiSchema";
import { CHECK_LIMS, SITE_TITLE } from "../helpers/strings";
import { CaseInfo, deserializeCase } from "../schemas/ApiSchema";
import { CustomError, dataService } from "../services/data.service";
import {
  LimsOptions,
  selectLimsOptionLabel,
  selectLimsOptions,
  selectPathologistLabel,
  selectUserGroupLabel,
} from "../store/metadataSlice";
import ErrorPage from "./ErrorPage";

export enum CaseDialogType {
  SAVE = "CaseDialogSave",
  STATE = "CaseDialogState",
}

const CaseEdit = (): JSX.Element => {
  // Get lab (CYT) number from route path
  const { labNumber } = useParams();
  const navigate = useNavigate();

  // Local state
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<CustomError>();
  const [formData, setFormData] = useState<CaseUiData>();
  const [caseData, setCaseData] = useState<CaseUiData>();
  const [caseInfo, setCaseInfo] = useState<CaseInfo>();
  const [showCaseDialog, setShowCaseDialog] = useState<CaseDialogType>();

  // Destructure useful properties from local state
  const { caseOrigin, clinician, consultant, userGroupId, pathologistId, specimen } =
    caseData || {};
  const { caseVersionId, permissions } = caseInfo || {};
  const { canEditCase, operations } = permissions || {};

  // Redux
  const activeLimsOptions = useSelector(selectLimsOptions);
  const userGroupName = useSelector(selectUserGroupLabel(userGroupId));
  const pathologistName = useSelector(selectPathologistLabel(pathologistId));
  const caseOriginName = useSelector(selectLimsOptionLabel("caseOrigins", caseOrigin));
  const consultantName = useSelector(selectLimsOptionLabel("consultants", consultant));

  // Track component mount to prevent additional GET request
  const isInitialMount = useRef(true);

  useEffect(() => {
    const fetchCase = async () => {
      const response = await dataService.getCase(labNumber ?? "");
      if (response.data) {
        const { caseData, caseInfo } = response.data;
        setCaseData(deserializeCase(caseData));
        setCaseInfo(caseInfo);
        document.title = `${caseData.labNumber} | ${SITE_TITLE}`;
      } else {
        setError(response.error);
      }
      setLoading(false);
    };
    // Prevent duplicate GET request when component is mounted
    if (isInitialMount.current || !!caseVersionId) {
      isInitialMount.current = false;
      fetchCase();
    }
  }, [caseVersionId]); // Re-fetch case after every update, lock or unlock

  const canLockCase: boolean =
    operations?.some(({ operation }) => operation === "Lock") ?? false;

  // When a dropdown value in caseData isn't available in limsOptions
  // then prepend the option to the necessary dropdown list with the
  // [Check LIMS] label.
  const getLimsOptions = (
    category: keyof LimsOptions,
    value = ""
  ): FormSelectOption[] => {
    return !activeLimsOptions[category].some((o) => o.value === value)
      ? [{ label: CHECK_LIMS, value }, ...activeLimsOptions[category]]
      : activeLimsOptions[category];
  };

  const limsOptions: LimsOptions = {
    specimens: getLimsOptions("specimens", specimen),
    clinicians: getLimsOptions("clinicians", clinician),
    caseOrigins: getLimsOptions("caseOrigins", caseOrigin),
    consultants: getLimsOptions("consultants", consultant),
  };

  // Allow case state banner to trigger form submission
  const formRef = useRef<FormRef | null>(null);
  const submitForm = () => formRef.current?.submitForm();

  // Make the form values available to CaseSaveDialog.tsx
  const handleSubmit = (form: CaseUiData) => {
    setFormData(form);
    openCaseDialog(CaseDialogType.SAVE);
  };

  const openCaseDialog = (dialog: CaseDialogType, e?: MouseEvent): void => {
    e?.preventDefault();
    setShowCaseDialog(dialog);
  };

  const closeCaseDialog = (e?: MouseEvent): void => {
    e?.preventDefault();
    setShowCaseDialog(undefined);
  };

  // Keep page URL in sync with lab number (which may have changed)
  const updatePageUrl = (): void => {
    if (formData?.labNumber) {
      navigate(`/cases/${formData.labNumber}`, {
        preventScrollReset: true,
        replace: true,
      });
    }
  };

  const updateCaseVersionId = (caseVersionId: string): void => {
    setCaseInfo((prevState) => (prevState ? { ...prevState, caseVersionId } : undefined));
  };

  if (error) {
    return <ErrorPage title={error.error} subtitle={error.msg} />;
  }

  return (
    <>
      <div className="container">
        {loading && <PageHeader title="Loading..." />}
        {caseData && caseInfo && (
          <>
            <PageHeader
              title={`Case ${caseData.labNumber}`}
              subtitle={userGroupId ? userGroupName : caseOriginName}
            />
            <div className="columns">
              <div className="column is-one-quarter">
                <CaseTimeline caseData={caseData} caseInfo={caseInfo} />
              </div>
              <div className="column is-three-quarters">
                <CaseStateBanner
                  caseInfo={caseInfo}
                  pathologistName={pathologistName || consultantName}
                  submitCaseForm={submitForm}
                  openCaseDialog={openCaseDialog}
                />
                <FormWrapper
                  ref={formRef}
                  formMode="update"
                  formLocked={!canEditCase}
                  formValues={caseData}
                  limsOptions={limsOptions}
                  onSubmit={handleSubmit}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {labNumber && formData && showCaseDialog === CaseDialogType.SAVE && (
        <CaseSaveDialog
          formMode="update"
          formData={formData}
          labNumber={labNumber}
          canLockCase={canLockCase}
          caseVersionId={caseVersionId}
          updatePage={updatePageUrl}
          updateCaseVersionId={updateCaseVersionId}
          openCaseDialog={openCaseDialog}
          closeCaseDialog={closeCaseDialog}
        />
      )}
      {labNumber && caseInfo && showCaseDialog === CaseDialogType.STATE && (
        <CaseStateDialog
          labNumber={labNumber}
          caseInfo={caseInfo}
          updateCaseVersionId={updateCaseVersionId}
          closeCaseDialog={closeCaseDialog}
        />
      )}
    </>
  );
};

export default CaseEdit;
