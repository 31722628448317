import { ApiResult } from "../services/data.service";

export type Special = {
  id: string;
  dateRequested: string;
  requestedByName: string | null;
  labNumber: string;
  recordNumber: string;
  block: string;
  details: string;
  state: SpecialState;
};

export enum SpecialState {
  NEW = "New",
  ORDERED = "Ordered",
  CANCELLED = "Cancelled",
  COMPLETE = "Complete",
}

// Special requests can never be un-ordered, ie. returned to "New"
export type SpecialTransition = Exclude<SpecialState, SpecialState.NEW>;

export interface SpecialTransitionOperation {
  transition: SpecialTransition;
  allowedWhen: SpecialState[];
  request: () => Promise<ApiResult<boolean>>;
  label: string;
  description: string;
  successMessage: string;
}
