import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosInstance, AxiosResponse, isAxiosError } from "axios";

import { SlideLink } from "../components/specials/SlideLinks";
import { CaseUiData } from "../forms/schema/CaseUiSchema";
import {
  CaseStateReasonForChange,
  CaseStateTransitionResponse,
  GetCaseResponse,
  GetCasesResponse,
  GetUsersResponse,
  SaveCaseResponse,
  serializeCase,
} from "../schemas/ApiSchema";
import { PortalUserForm } from "../schemas/PortalUserSchema";
import { SpecialTransition } from "../schemas/SpecialRequestSchema";
import { UserGroupFormData } from "../schemas/UserGroupSchema";
import { UserType } from "../schemas/UserSchema";
import { SearchParameters } from "../store/caseListSlice";
import { BootData } from "../store/metadataSlice";
import { GetSpecialsResponse } from "../store/specialsListSlice";

/**
 * This version number tracks changes to the API or JSON schemas,
 * e.g. those arising from changes to questions and/or answers in the UI.
 *
 * IF UPDATING THIS VALUE YOU MUST ALSO UPDATE THE VALUE IN PATHKIT
 *
 * 2.0  LabKit data first exposed.
 * 2.1  The previous case version ID is required when updating a case to prevent clashes.
 */
export const API_VERSION = "2.1";

interface OkResult<T> {
  data: T;
  error: null;
}

interface ErrorResult {
  data: null;
  error: CustomError;
}

export type ApiResult<T> = OkResult<T> | ErrorResult;

export interface CustomError {
  error: string;
  msg: string;
}

const getRestApi = (): AxiosInstance => {
  const restApi = axios.create({
    // Use Vite server proxy for /api requests in local dev
    baseURL: import.meta.env.VITE_APP_BACKEND_URL ?? "/api",
  });
  restApi.interceptors.request.use((config) => {
    // fetchAuthSession() refreshes tokens with Cognito if needed
    return fetchAuthSession()
      .then((session) => {
        config.headers.Authorization = `Bearer ${session.tokens?.idToken?.toString()}`;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  return restApi;
};

const getBootData = async () => {
  try {
    const response = await getRestApi().get("/labkit/metadata");
    const data = parseResponse(response);
    return okResult(data as BootData);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getUsers = async (userType: UserType, page: number = 1) => {
  try {
    const response = await getRestApi().get(`/users/${userType.toLowerCase()}`, {
      params: { page },
    });
    const data = parseResponse(response);
    return okResult(data as GetUsersResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const createUserGroup = async (form: UserGroupFormData) => {
  try {
    const response = await getRestApi().post("/user-group", form);
    const data = parseResponse(response);
    return okResult(data.userGroupId as string);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const updateUserGroup = async (userGroupId: string, form: UserGroupFormData) => {
  try {
    const response = await getRestApi().put(`/user-group/${userGroupId}`, form);
    const data = parseResponse(response);
    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const createPortalUser = async (form: PortalUserForm) => {
  try {
    const response = await getRestApi().post("/user", form);
    const data = parseResponse(response);
    return okResult(data.userId as string);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const updatePortalUser = async (userId: string, form: PortalUserForm) => {
  try {
    const response = await getRestApi().put(`/user/${userId}`, form);
    const data = parseResponse(response);
    return okResult(data.userId as string);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const activatePortalUser = async (userId: string) => {
  try {
    const response = await getRestApi().put(`/user/${userId}/activate`);
    const data = parseResponse(response);
    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const deactivatePortalUser = async (userId: string) => {
  try {
    const response = await getRestApi().put(`/user/${userId}/deactivate`);
    const data = parseResponse(response);
    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const disableMfaForPortalUser = async (userId: string) => {
  try {
    const response = await getRestApi().put(`/user/${userId}/disable-mfa`);
    const data = parseResponse(response);
    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const deletePortalUser = async (userId: string) => {
  try {
    const response = await getRestApi().delete(`/user/${userId}`);
    const data = parseResponse(response);
    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getSlides = async (labNumber: string) => {
  try {
    const response = await getRestApi().get(`/slides/${labNumber}`);
    const data = parseResponse(response);
    return okResult(data.slides as SlideLink[]);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getSpecials = async () => {
  try {
    const response = await getRestApi().get("/specials");
    const data = parseResponse(response);
    return okResult(data as GetSpecialsResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const updateSpecial = async (requestId: string, state: SpecialTransition) => {
  try {
    const response = await getRestApi().put(
      `/special/${requestId}`,
      {},
      { params: { state: state.toLowerCase() } }
    );
    const data = parseResponse(response);
    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getCases = async (page: number = 1, search: SearchParameters) => {
  try {
    const response = await getRestApi().get("/cases", {
      params: { page, ...search },
    });
    const data = parseResponse(response);
    return okResult(data as GetCasesResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getCase = async (labNumber: string) => {
  try {
    const response = await getRestApi().get(`/case/${labNumber}`);
    const data = parseResponse(response);
    const { caseData, ...caseInfo } = data;
    return okResult({ caseData, caseInfo } as GetCaseResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const createCase = async (form: CaseUiData) => {
  try {
    const { ...caseData } = serializeCase(form);
    const payload = { caseData, apiVersion: API_VERSION };
    const response = await getRestApi().post("/case", payload);
    const data = parseResponse(response);
    const { limsCaseId, caseVersionId } = data;
    return okResult({ limsCaseId, caseVersionId } as SaveCaseResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const updateCase = async (
  labNumber: string,
  form: CaseUiData,
  previousCaseVersion: string,
  submitChanges: boolean = true
) => {
  try {
    const { ...caseData } = serializeCase(form);
    const payload = {
      caseData,
      previousCaseVersion,
      apiVersion: API_VERSION,
      submitChanges,
    };
    const response = await getRestApi().put(`/case/${labNumber}`, payload);
    const data = parseResponse(response);
    const { limsCaseId, caseVersionId } = data;
    return okResult({ limsCaseId, caseVersionId } as SaveCaseResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const lockCase = async (
  labNumber: string,
  previousCaseVersion: string,
  reason: CaseStateReasonForChange,
  reissueReport: boolean = true
) => {
  try {
    const payload = { reason, reissueReport, previousCaseVersion };
    const response = await getRestApi().put(`/case/${labNumber}/lock`, payload);
    const data = parseResponse(response);
    return okResult({ ...data } as CaseStateTransitionResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const unlockCase = async (
  labNumber: string,
  previousCaseVersion: string,
  reason: CaseStateReasonForChange
) => {
  try {
    const payload = { reason, previousCaseVersion };
    const response = await getRestApi().put(`/case/${labNumber}/unlock`, payload);
    const data = parseResponse(response);
    return okResult({ ...data } as CaseStateTransitionResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const parseResponse = (response: AxiosResponse) => {
  if (![200, 201].includes(response.status)) {
    throw Error(response.statusText);
  }
  if (!response.data) {
    return [];
  }
  let data = response.data;
  if (typeof data !== "object") {
    data = [];
  }
  return data;
};

const okResult = <T>(data: T): OkResult<T> => ({
  data,
  error: null,
});

const errorResult = (error: unknown): ErrorResult => ({
  data: null,
  error:
    isAxiosError(error) && error.response
      ? {
          // Try to get custom errors, fall back to Axios errors
          error: error.response.data.error ?? error.response.statusText,
          msg: error.response.data.msg ?? error.message,
        }
      : {
          error: "Error",
          msg: "",
        },
});

export const dataService = {
  getBootData,
  getUsers,
  createUserGroup,
  updateUserGroup,
  createPortalUser,
  updatePortalUser,
  activatePortalUser,
  deactivatePortalUser,
  disableMfaForPortalUser,
  deletePortalUser,
  getSlides,
  getSpecials,
  updateSpecial,
  getCases,
  getCase,
  createCase,
  updateCase,
  lockCase,
  unlockCase,
};
