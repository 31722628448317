import React from "react";
import { useFormContext } from "react-hook-form";

import CheckboxGroup from "../../../components/forms/CheckboxGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

interface ReasonsForFailedAttemptProps {
  disabled: boolean;
}

const ReasonsForFailedAttempt = ({
  disabled,
}: ReasonsForFailedAttemptProps): JSX.Element => {
  const { register } = useFormContext<CaseUiData>();

  return (
    <CheckboxGroup
      id="reasonsForFailedAttempt"
      disabled={disabled}
      label="Reason for failure"
      options={CaseSchemaOptions.reasonsForFailedAttempt}
      {...register("reasonsForFailedAttempt")}
    />
  );
};

export default ReasonsForFailedAttempt;
