import React from "react";

interface DialogTitleProps {
  children: React.ReactNode;
  testId?: string;
}

const DialogTitle = ({ children, testId }: DialogTitleProps): JSX.Element => {
  return (
    <h3 className="title is-4" data-testid={testId}>
      {children}
    </h3>
  );
};

export default DialogTitle;
